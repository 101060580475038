.log-heading {
  color: var(--primary);
}

.log-header {
  display: flex;
  color: var(--primary);
}

.log-header h4 {
  width: 25%;
}

.log-header h5 {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.log-header h5 span {
  margin-left: 6px;
}

.log-item {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  padding: 24px;
  border-radius: 16px;
  background: var(--gradient);
  backdrop-filter: blur(8px);
  border: 1px solid #ffffff30;
  margin-bottom: 6px;
  color: var(--primary);
}

.log-item p {
  width: 25%;
}

.log-item h3 {
  margin-bottom: 0;
}

.log-item .material-icons {
  font-size: 18px;
  cursor: pointer;
  text-align: end;
  width: 25%;
  opacity: 0;
  transition: 0.3s ease;
}

.log-item:hover .material-icons {
  opacity: 1;
}

.log-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: var(--primary);
}

.new-entry-form {
  margin-top: 12px;
  display: flex;
  justify-content: space-evenly;
}

.new-entry-form input {
  width: 25%;
  margin-right: 8px;
  box-shadow: var(--dropshadow);
  padding: 6px;
  border-radius: 6px;
  border: 0.5px solid #ffffff30;
  backdrop-filter: blur(8px);
  background: var(--gradient);
  color: var(--primary);
  font-family: var(--fonts);
}

.new-entry-form input::placeholder {
  color: var(--foreground);
}

.new-entry-form button {
  background-color: var(--accent);
  border: none;
  border-radius: 6px;
  color: var(--primary);
  width: 25%;
  margin-right: 8px;
  box-shadow: var(--dropshadow);
}
