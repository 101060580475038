@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);

body {
  margin: 0;
  font-family: var(--fonts);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: right url("../../public/Background.png");
  background-size: cover;
}

* {
  box-sizing: border-box;
}

:root {
  --primary: rgb(255, 255, 255);
  --secondary: rgb(33, 33, 33);
  --background: rgb(0, 0, 0);
  --foreground: rgb(235, 235, 235);
  --accent: rgb(255, 208, 0);
  --gradient: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(112, 112, 112, 0.6) 100%
  );
  --padding: 5%;
  --dropshadow: 2px 2px 8px rgba(33, 33, 33, 0.03);
  --fonts: -apple-system, Inter, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow-y: none;
}

.App {
  color: var(--background);
  width: 90%;
  height: 100vh;
  overflow-y: auto;
  padding: var(--padding);
}

.link {
  color: var(--accent);
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

::selection {
  background: rgba(255, 208, 0, 0.4);
}

@media screen and (max-width: 980px) {
  #root {
    flex-direction: column-reverse;
  }
  .App {
    width: 100%;
    padding: calc(var(--padding) * 2);
  }
}
