.settings-modal {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  backdrop-filter: blur(4px);
  background: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.settings-modal-open {
  display: flex;
}

.settings {
  display: flex;
  flex-direction: column;
  padding: var(--padding);
  width: 80%;
  height: 80%;
  background: var(--gradient);
  border-radius: 16px;
  border: 1px solid #ffffff30;
  color: var(--primary);
}

.settings-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.settings-close-button {
  cursor: pointer;
  color: var(--primary);
  transition: all 0.2s ease;
}

.settings-close-button:hover {
  color: var(--accent);
}

.settings-content {
  overflow-y: auto;
}

.settings-content button {
  background-color: var(--accent);
  border: none;
  border-radius: 6px;
  color: var(--primary);
  margin-right: 8px;
  box-shadow: var(--dropshadow);
  padding: 14px;
  cursor: pointer;
}

.settings-heading {
  margin: 16px 0;
}
