.filters-modal {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 1;
  backdrop-filter: blur(4px);
  background: #00000080;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.filters-modal-open {
  display: flex;
}

.filters {
  display: flex;
  flex-direction: column;
  padding: var(--padding);
  width: 80%;
  height: 80%;
  background: var(--gradient);
  border-radius: 16px;
  border: 1px solid #ffffff30;
  color: var(--primary);
}

.filters-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filters-close-button {
  cursor: pointer;
}

.filters-content {
  overflow-y: auto;
}

.filters-content button {
  background-color: var(--accent);
  border: none;
  border-radius: 6px;
  color: var(--primary);
  margin-right: 8px;
  box-shadow: var(--dropshadow);
  padding: 14px;
  cursor: pointer;
}

.filters-heading {
  margin: 16px 0;
}

.sorting-form,
.filters-form {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.sorting-form > label,
.filters-form > label {
  margin-bottom: 6px;
}

/* .filters-form > label > input {
  width: 25%;
  margin-right: 8px;
  box-shadow: var(--dropshadow);
  padding: 6px;
  border-radius: 6px;
  border: 0.5px solid #ffffff30;
  backdrop-filter: blur(8px);
  background: var(--gradient);
  color: var(--primary);
  font-family: var(--fonts);
} */
