.dashboard-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 8px;
}

.dashboard-heading {
  color: var(--primary);
}

.dashboard-message {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 12px;
  padding: 24px;
  border-radius: 16px;
  background: var(--gradient);
  backdrop-filter: blur(8px);
  border: 1px solid #ffffff30;
  margin-bottom: 6px;
  color: var(--primary);
}

.dashboard-message h3 {
  margin-bottom: 0;
}

.card:nth-child(3n) {
  grid-column: span 2;
}

.card {
  background: var(--gradient);
  color: var(--primary);
  border-radius: 16px;
  padding: 24px;
  box-shadow: var(--dropshadow);
  backdrop-filter: blur(8px);
  border: 1px solid #ffffff30;
}

.card-total {
  margin-bottom: 6px;
}

.card-total ~ small {
  color: var(--accent);
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

@media screen and (max-width: 980px) {
  .dashboard-section {
    grid-template-columns: 1fr;
  }
  .card {
    width: 100%;
  }
  .card:nth-child(3n) {
    grid-column: auto;
  }
}

.greyed-out {
  color: var(--foreground);
  opacity: 0.5;
}
