.menu {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  transition: 0.2s cubic-bezier(0, 0.75, 0, 0.75);
  width: 13%;
  color: var(--primary);
  background: var(--gradient);
  backdrop-filter: blur(8px);
  border: 1px solid #ffffff30;
  padding: var(--padding);
}

.menu-open {
  width: 30%;
}

.menu ul {
  display: none;
  list-style: none;
  padding: 0;
}

.list-open {
  display: block !important;
}

.menu ul a {
  transition: 0.2s ease-in;
  text-decoration: none;
}

.menu ul a li {
  margin-bottom: 6px;
  color: var(--primary);
  transition: 0.2s ease-in;
  font-size: 18px;
  font-weight: bold;
}

.menu ul a:hover {
  text-decoration: underline;
  text-decoration-color: var(--accent);
}

.menu ul a li:hover {
  color: var(--accent);
}

.menu-button {
  cursor: pointer;
  color: var(--primary);
  transition: all 0.2s ease;
}

.menu-button:hover {
  color: var(--accent);
}

.settings-button {
  cursor: pointer;
  color: var(--primary);
  transition: all 0.2s ease;
}

.settings-button:hover {
  color: var(--accent);
}

@media screen and (max-width: 980px) {
  .menu {
    width: 100%;
    height: 10%;
    border-radius: 16px 16px 0 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    flex-direction: row;
  }
  .menu-open {
    width: 100%;
    height: 30%;
  }
}
